var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "text-h5",
                      attrs: { color: "secondary white--text" },
                    },
                    [_vm._v(" Create new step ")]
                  ),
                  _c("AError", { attrs: { api: _vm.api } }),
                  _c("v-text-field", {
                    staticClass: "mt-5",
                    attrs: { dense: "", outlined: "", label: "Title" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _c("mavon-editor", {
                    staticClass: "my-5",
                    staticStyle: {
                      "z-index": "0",
                      "min-height": "400px",
                      height: "auto",
                      width: "100%",
                    },
                    attrs: {
                      toolbars: _vm.toolbars,
                      height: "auto",
                      width: "auto",
                      "min-height": "400px",
                      subfield: false,
                      language: "en",
                      placeholder: "New step",
                      editable: true,
                    },
                    model: {
                      value: _vm.step,
                      callback: function ($$v) {
                        _vm.step = $$v
                      },
                      expression: "step",
                    },
                  }),
                  _c("v-file-input", {
                    attrs: {
                      accept: "image/png, image/jpeg ",
                      color: "primary",
                      "prepend-icon": "mdi-camera",
                      outlined: "",
                      label: "Upload image (if have any)",
                    },
                    model: {
                      value: _vm.attachment,
                      callback: function ($$v) {
                        _vm.attachment = $$v
                      },
                      expression: "attachment",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start" },
                    [
                      _vm.order != null
                        ? _c("v-text-field", {
                            attrs: { dense: "", outlined: "", label: "Order" },
                            model: {
                              value: _vm.order,
                              callback: function ($$v) {
                                _vm.order = $$v
                              },
                              expression: "order",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", plain: "" },
                          on: { click: _vm.redirectBack },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.api.isLoading,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }